
import { Vue, Options } from "vue-class-component";
import Modal from "./modal.vue"

@Options({
    components: {
        Modal
    }
})

export default class Geolocation extends Vue {
  isModal = false
  showModal() {
    this.isModal = !this.isModal;
  }
}
