
import {
  ApiRequestList,
  ErrorMessageEntities,
  ModalMessageEntities,
  OptionsClass,
} from "@/domain/entities/MainApp";
import { Options, Vue } from "vue-class-component";
import Geolocation from "../geolocation/index.vue";
import Repetable from "../repeatable/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ClientController } from "@/app/ui/controllers/ClientController";
import { CnManifestController } from "@/app/ui/controllers/CnManifestController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { debounce } from "lodash";
import {
  CLIENT_ERROR,
  formatDate,
  formatDateNormal,
} from "@/app/infrastructures/misc/Utils";

import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  getIndonesianDay,
  calculateHourDifference,
} from "@/app/infrastructures/misc/common-library/modules/Date";

import { ApiStateData } from "@/domain/entities/Api";
import { CourierManagementController } from "@/app/ui/controllers/CourierManagementController";
import { ApiRequestCourierVehicleList } from "@/data/payload/api/CourierManagementApiRequest";
import { ListPickupController } from "@/app/ui/controllers/ListPickupController";
import { PickupFormApiRequest } from "@/data/payload/api/PickupApiRequest";
import PickupCorporateAddressSection from "@/app/ui/views/pickup/components/pickup-corporate-address-section.vue";
import { PickupScheduleController } from "@/app/ui/controllers/PickupScheduleController";
import { PickupCorporateAddress } from "@/domain/entities/PickupSchedule";
import SavedAddressList from "@/app/ui/views/pickup/components/saved-address-list/index.vue";
import ModalAddNewAddress from "../modal-add-new-address/index.vue";

@Options({
  components: {
    Geolocation,
    Repetable,
    PickupCorporateAddressSection,
    SavedAddressList,
    ModalAddNewAddress,
  },
})
export default class PickupForm extends Vue {
  controller = ListPickupController;
  accountController = AccountController;
  cnManifestController = CnManifestController;
  clientController = ClientController;
  locationController = LocationController;
  courierManagementController = CourierManagementController;

  isTransportTypeSelectOpen = false;
  isLoadingSubmit = false;
  modalMessageEntities = new ModalMessageEntities();
  modalErrorMessageEntities = new ErrorMessageEntities();
  showModalSuccess = false;
  showModalFailed = false;
  showModalConfirm = false;
  isErrorPickupTime = false;
  modalConfirmEntities = new ModalMessageEntities({
    image: "badge-confirmation-general",
    title: "Simpan perubahan konfigurasi jadwal Pick Up?",
    message: "Pastikan data yang Anda masukkan sudah benar.",
  });
  apiDetailData = new ApiStateData();
  shipmentId = "";
  form = {
    clientId: new OptionsClass({}),
    clientPhone: "",
    clientParentCode: "",
    cityId: "",
    courierNote: "",
    transportType: "",
    serviceCode: [] as string[],
    estimateTotalKoli: "",
    estimateTotalTonase: "",
    pickupDate: "",
    time: "",
  };

  letterCodeOptions = [new OptionsClass({ name: "-", value: "" })];
  transportTypeOptions = [
    new OptionsClass({ name: "Pilih tipe armada", value: "" }),
  ];
  isLoadingAutofill = false;
  isSavedAddressListOpen = false;
  listDataSavedAddress: PickupCorporateAddress[] = [];
  isAddAddressOpen = false;

  get isClientBranch() {
    return this.accountController.accountData.isClientBranchAccount;
  }

  get isClientParent() {
    return this.accountController.accountData.isClientParentAccount;
  }

  get isAdmin() {
    return this.accountController.accountData.isInternalAccount;
  }

  get parentCode() {
    return this.accountController.accountData.account_type_detail.code;
  }

  get minDate() {
    return new Date(new Date().setDate(new Date().getDate()));
  }

  get clientOptions() {
    if (this.isClientParent) {
      const options = this.clientController.clientData.data.map((client) => {
        return new OptionsClass({
          code: client.code,
          name: client.companyName,
          value: client.id.toString(),
          label: client.phone,
        });
      });

      return options;
    }
    if (this.isAdmin) {
      const options = this.clientController.clientMappingData.map((client) => {
        return new OptionsClass({
          name: client.clientName,
          value: client.clientId.toString(),
          code: client.clientOriginCity.split("-")[0],
        });
      });

      return options;
    }

    return [];
  }

  get districtOptions() {
    return this.locationController.districtData.districtData.map((item) => {
      return new OptionsClass({
        name: item.districtNameCityName,
        value: item.code,
        code: item.cityCode,
      });
    });
  }

  get isPickupOver(): boolean {
    return this.form.time > "18:00";
  }

  get listPickupCorporateAddress(): PickupCorporateAddress[] {
    return PickupScheduleController.listPickupCorporateAddress;
  }
  get selectedAddress(): PickupCorporateAddress {
    return PickupScheduleController.selectedAddress;
  }

  get isFormValid(): boolean {
    const { pcaDistrictId, pcaAddress, pcaLatlon } = this.selectedAddress;
    return (
      !!this.form.clientId.value &&
      !!pcaDistrictId &&
      !!this.form.cityId &&
      !!pcaAddress &&
      !!pcaLatlon &&
      !!this.form.transportType &&
      !!this.form.serviceCode.length &&
      !!this.form.pickupDate &&
      !!this.form.time
    );
  }

  get pickupSuccessModalData() {
    return {
      transportType: this.form.transportType,
      serviceCodes: this.form.serviceCode.join(", "),
      pickupDate: this.form.pickupDate
        ? formatDateNormal(this.form.pickupDate, "D MMMM YYYY")
        : "-",
      pickupDay: getIndonesianDay(this.form.pickupDate),
      timePickup: this.form.time
        ? `${formatDate(this.form.time, "HH:mm")}`
        : "-",
    };
  }

  get id() {
    return this.$route.params.id;
  }

  mounted(): void {
    this.fetchClientOptions("");
    this.fetchVehicles("");

    if (this.isClientBranch) {
      this.setAutofill(
        this.accountController.accountData.account_type_detail.id
      );
    }
  }

  unmounted() {
    PickupScheduleController.setSelectedAddressPickupCorporateAddress(
      new PickupCorporateAddress()
    );
  }

  fetchClientOptions(search: string): void {
    if (this.isAdmin) {
      this.clientController._onGetCLientMappingList({
        isBranch: true,
        isParent: false,
        limit: 10,
        page: 1,
        search,
      });
    }

    if (this.isClientParent) {
      this.clientController._onGetList({
        clientParentId: this.accountController.accountData.account_type_detail
          .id,
        limit: 10,
        page: 1,
        search,
        status: "",
      });
    }
  }

  fetchDistricts(search: string, cityCode = "", limit = 10) {
    return new Promise((resolve) => {
      this.locationController
        .getDistrictList({
          page: 1,
          limit: limit,
          search,
          status: "active",
          cache: true,
          cityCode: cityCode,
          excludeCountry: "",
          type: "",
        })
        .then((res) => resolve(res));
    });
  }

  fetchVehicles(search = "") {
    this.courierManagementController
      .getListVehicle(new ApiRequestCourierVehicleList({ search }))
      .then((res) => {
        this.transportTypeOptions = res.data.map(
          (item: { id: number; vehicle_name: string }) => {
            return new OptionsClass({
              name: item.vehicle_name,
              value: item.vehicle_name,
            });
          }
        );
        this.transportTypeOptions.unshift(
          new OptionsClass({ name: "Pilih tipe armada", value: "" })
        );
      });
  }

  onSearchDistricts = debounce((search: string) => {
    this.fetchDistricts(search);
  }, 500);

  onSearchClient = debounce((search: string) => {
    this.fetchClientOptions(search);
  }, 500);

  async onSelectClient(value: OptionsClass) {
    if (value?.value) {
      this.setAutofill(Number(value.value));

      PickupScheduleController.setSelectedAddressPickupCorporateAddress(
        new PickupCorporateAddress({
          ...this.selectedAddress,
          pcaClientId: Number(value?.value),
        })
      );

      if (this.isAdmin) {
        this.updateListPickupCorporateAddress();
      }
    } else {
      this.form.cityId = "";
      this.letterCodeOptions = [new OptionsClass({ name: "-", value: "" })];
    }
  }
  onConfirmEdit(): void {
    this.showModalConfirm = true;
  }

  onBack(): void {
    this.$router.push("/pickup/list-pickup");
  }

  showErrorTimePickup() {
    this.modalErrorMessageEntities = new ErrorMessageEntities({
      type: CLIENT_ERROR,
      title: `Waktu Pick Up minimal 4 jam dari sekarang`,
      message: `Silahkan atur ulang waktu Pick Up Anda.`,
      isFailed: true,
      buttonSuccessText: "OK",
    });
    this.isErrorPickupTime = true;
    this.showModalFailed = true;
  }

  async onSubmit(): Promise<void> {
    this.showModalFailed = false;
    this.showModalSuccess = false;
    this.showModalConfirm = false;
    this.isErrorPickupTime = false;

    const pickupDateString = formatDateNormal(
      this.form.pickupDate,
      "YYYY-MM-DD"
    );

    if (calculateHourDifference(pickupDateString, this.form.time) < 4) {
      this.showErrorTimePickup();
      return;
    }

    const parentId = this.isClientParent
      ? this.parentCode
      : this.form.clientParentCode;

    const {
      pcaId,
      pcaAddress,
      pcaLatlon,
      pcaDistrict: { code },
    } = this.selectedAddress;

    const payload = new PickupFormApiRequest({
      clientId: String(this.form.clientId.code),
      clientParentId: String(parentId),
      client3lc: this.form.cityId,
      clientRef: this.form.clientId.name,
      origin: String(code),
      pickupCorporateAddressId: pcaId,
      pickupAddress: pcaAddress,
      courierNote: this.form.courierNote,
      geoloc: pcaLatlon,
      pickupDate: pickupDateString,
      pickupTime: this.form.time,
      transportType: this.form.transportType,
      serviceCode: this.form.serviceCode.join(","),
      estimateTotalKoli: Number(this.form.estimateTotalKoli),
      estimateTotalTonase: Number(this.form.estimateTotalTonase),
      pickupType: "instant",
      clientPhoneNumber: this.form.clientPhone,
    });

    try {
      MainAppController.showLoading();
      this.isLoadingSubmit = true;
      const resp = await this.controller.createPickup(payload);
      this.shipmentId = resp.shipmentId;
      this.modalMessageEntities = {
        ...this.modalMessageEntities,
        image: "badge-success",
      };
      this.showModalSuccess = true;
    } catch (error) {
      const message = error.response.data.message;
      this.modalErrorMessageEntities = new ErrorMessageEntities({
        type: CLIENT_ERROR,
        title: `Pick Up langsung gagal dibuat`,
        message:
          typeof message === "string"
            ? "Permintaan konfigurasi jadwal baru Pick Up gagal dibuat. Cek & coba kembali."
            : message.id,
        onTryAgain: this.onSubmit,
        isFailed: true,
        buttonSuccessText: "Ulangi",
      });
      this.showModalFailed = true;
    } finally {
      this.isLoadingSubmit = false;
      MainAppController.closeLoading();
    }
  }

  onCloseModalSucces(): void {
    this.showModalSuccess = false;
    this.$router.push("/pickup/list-pickup");
  }

  async setAutofill(clientId: number): Promise<void> {
    try {
      // get detail client
      this.isLoadingAutofill = true;
      const clientDetail = await this.clientController.fetchClientDetail(
        clientId
      );
      // set 3lc options
      this.letterCodeOptions = [
        new OptionsClass({
          name: clientDetail.clientCityCode,
          value: clientDetail.clientCityCode,
        }),
      ];
      // autofill 3lc, address & district
      this.form = {
        ...this.form,
        cityId: clientDetail.clientCityCode,
        clientPhone: clientDetail.clientPhone,
        clientParentCode: clientDetail.clientParentCode,
        clientId: new OptionsClass({
          name: clientDetail.clientName,
          value: clientDetail.clientId.toString(),
          code: clientDetail.clientCode,
        }),
      };
      await this.fetchDistricts(clientDetail.clientDistrictName, "", 100);
    } catch (error) {
      this.isLoadingAutofill = false;
    } finally {
      this.isLoadingAutofill = false;
    }
  }

  onAddressListUpdated(listDataSavedAddress: PickupCorporateAddress[]) {
    this.listDataSavedAddress = listDataSavedAddress;
  }

  onClickPilihAlamatLain() {
    this.isSavedAddressListOpen = true;
  }

  onSelectAddress(data: PickupCorporateAddress) {
    PickupScheduleController.setSelectedAddressPickupCorporateAddress(data);
  }

  async updateListPickupCorporateAddress(isSelectAddress = true) {
    await PickupScheduleController.getListPickupCorporateAddress(
      new ApiRequestList({
        clientId: this.isAdmin ? this.form?.clientId?.value : undefined,
        page: 1,
        limit: 10,
        isTotalData: false,
        selectAddress: isSelectAddress,
      })
    );
  }

  onClickTambahAlamat() {
    this.isAddAddressOpen = !this.isAddAddressOpen;
  }

  onSuccessTambahAlamat() {
    this.isAddAddressOpen = false;
    this.updateListPickupCorporateAddress();
  }
}
