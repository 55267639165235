
import { Vue, Options, prop } from "vue-class-component";

class Props {
  intervalValue = prop<number>({
    default: 1,
    required: true,
    type: Number
  });
  daysValue = prop<string[]>({
    default: [],
    required: true,
    type: Array
  });
}

@Options({
  emits: ["setInterval", "setDays"]
})
export default class Repetable extends Vue.with(Props) {
  optionWeeks = [
    {
      name: "1 Minggu",
      value: 1,
    },
    {
      name: "2 Minggu",
      value: 2,
    },
  ];

  isMenu = false;
  showMenu() {
    this.isMenu = !this.isMenu;
  }

  selectWeek(value: number) {
    this.interval = value;
  }

  days = [
    {
      name: "Senin",
      value: "monday",
      status: false,
    },
    {
      name: "Selasa",
      value: "tuesday",
      status: false,
    },
    {
      name: "Rabu",
      value: "wednesday",
      status: false,
    },
    {
      name: "Kamis",
      value: "thursday",
      status: false,
    },
    {
      name: "Jumat",
      value: "friday",
      status: false,
    },
    {
      name: "Sabtu",
      value: "saturday",
      status: false,
    },
    {
      name: "Minggu",
      value: "sunday",
      status: false,
    },
  ];

  get daysGetter() {
    const mappedDays = this.days.map(day => {
      return {
        ...day,
        status: this.daysValue.includes(day.value)
      };
    });

    this.days = mappedDays;
    return this.days;
  }

  get interval() {
    return this.intervalValue
  }

  set interval(val) {
    this.$emit("setInterval", val);
  }

  setday(index: number) {
    if (this.days[index].status) {
      this.days[index].status = false;
    } else {
      this.days[index].status = true;
    }

    const daysSelected = this.days.filter((item: any) => item.status);
    const daysFiltered = daysSelected.map((item: any) => item.value)
    this.$emit("setDays", daysFiltered)
  }
}
