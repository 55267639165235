/* eslint-disable @typescript-eslint/camelcase */
import {
  ClientPartnerOptions,
  DestinationCityOptions,
  SttNumbersOptions,
  CnManifestEntities
} from "@/domain/entities/CnManifest";
import { Pagination } from "@/domain/entities/Pagination";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { CnManifestPresenter } from "@/app/ui/presenters/CnManifestPresenter";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";

import { AccountController } from "./AccountController";
export interface CnManifestState {
  clientPartnerOptions: ClientPartnerOptions[];
  destinationCityOptions: DestinationCityOptions[];
  sttNumbersOptions: SttNumbersOptions[];
  cnManifests: CnManifestEntities;
  loadingClientPartnerOptions: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "cn_manifest" })
class CnManifestStore extends VuexModule implements CnManifestState {
  public clientPartnerOptions: Array<ClientPartnerOptions> = [];
  public destinationCityOptions: Array<DestinationCityOptions> = [];
  public sttNumbersOptions: Array<SttNumbersOptions> = [];
  public cnManifests = new CnManifestEntities(new Pagination(1, 10), []);
  public loadingClientPartnerOptions = true;
  public loadingDestinationCityOptions = false;
  public loadingCnManifests = false;
  public isError = false;
  public isErrorDownload = false;
  public errorCause = "";
  public cnManifestFilter: any = {
    sttListingType: "booking_date",
    userType: "console",
    clientPartner: "",
    destinationCity: "",
    startDate: "",
    endDate: "",
    sttNumbers: []
  };
  public cacheCnManifestFilter: any = {
    sttListingType: "booking_date",
    userType: "console",
    clientPartner: "",
    destinationCity: "",
    startDate: "",
    endDate: "",
    sttNumbers: []
  };
  public firstRequest = false;

  @Action
  public fetchListClientPartnerOptions(params: {
    page: number;
    limit: number;
    search: string;
    isParent: boolean;
  }) {
    this.setLoadingClientPartnerOptions(true);
    const presenter = container.resolve(CnManifestPresenter);
    presenter
      .getListClientPartnerOptions(
        params.page,
        params.limit,
        params.search,
        this.cnManifestFilter.userType,
        params.isParent
      )
      .then((res: ClientPartnerOptions[]) => {
        this.setClientPartnerOptions(res);
      })
      .finally(() => {
        this.setLoadingClientPartnerOptions(false);
      });
  }

  @Action
  public fetchListClientOptions(params: {
    page: number;
    limit: number;
    search: string;
    type: string;
    isParent: boolean;
  }) {
    this.setLoadingClientPartnerOptions(true);
    const presenter = container.resolve(CnManifestPresenter);
    presenter
      .getListClientPartnerOptions(
        params.page,
        params.limit,
        params.search,
        params.type,
        params.isParent
      )
      .then((res: ClientPartnerOptions[]) => {
        this.setClientPartnerOptions(res);
      })
      .finally(() => {
        this.setLoadingClientPartnerOptions(false);
      });
  }

  @Action
  public fetchListDestinationCityOptions(params: {
    page: number;
    limit: number;
    search: string;
  }) {
    this.setLoadingDestinationCityOptions(true);
    const presenter = container.resolve(CnManifestPresenter);
    presenter
      .getListDestinationCityOptions(params.page, params.limit, params.search)
      .then((res: DestinationCityOptions[]) => {
        this.setDestinationCityOptions(res);
      })
      .finally(() => {
        this.setLoadingDestinationCityOptions(false);
      });
  }

  @Action
  public fetchListSttNumbersOptions(params: RequestListBooking) {
    const presenter = container.resolve(CnManifestPresenter);
    presenter
      .getListSttNumbersOptions(params)
      .then((res: SttNumbersOptions[]) => {
        this.setSttNumbersOptions(res);
      });
  }

  @Action
  public fetchCnManifests() {
    this.setFirstRequest(false);
    this.setLoadingCnManifests(true);
    const presenter = container.resolve(CnManifestPresenter);
    presenter
      .getListCnManifest(
        this.cnManifests.pagination.page,
        this.cnManifests.pagination.limit,
        this.cnManifestFilter.sttListingType === "booking_date"
          ? this.cnManifestFilter.userType
          : "",
        this.cnManifestFilter.sttListingType === "booking_date"
          ? this.cnManifestFilter.userType === "customer"
            ? this.cnManifestFilter.clientPartner?.code
            : this.cnManifestFilter.clientPartner?.id || ""
          : "",
        this.cnManifestFilter.destinationCity?.code || "",
        this.cnManifestFilter.startDate
          ? this.cnManifestFilter.startDate.toLocaleDateString("fr-CA")
          : "",
        this.cnManifestFilter.endDate
          ? new Date(
              new Date(this.cnManifestFilter.endDate).setUTCHours(0)
            ).toLocaleDateString("fr-CA")
          : "",
        this.cnManifestFilter.sttNumbers
          .map((item: any) => {
            return item.name;
          })
          .toString(),
        this.cnManifestFilter.sttListingType === "booking_date"
          ? this.cnManifestFilter.userType === "console" ||
            this.cnManifestFilter.userType === "sub-console" ||
            this.cnManifestFilter.userType === "pos"
            ? "partner"
            : this.cnManifestFilter.userType
          : ""
      )
      .then((res: CnManifestEntities) => {
        this.setCnManifests(res);
        this.setCacheCnManifestFilterObject(
          JSON.parse(JSON.stringify(this.cnManifestFilter))
        );
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingCnManifests(false);
      });
  }

  @Action
  public exportManifestsToCsv() {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CnManifestPresenter);
    presenter
      .exportSttManifests(
        this.cnManifestFilter.sttListingType === "booking_date"
          ? this.cnManifestFilter.userType
          : "",
        this.cnManifestFilter.sttListingType === "booking_date"
          ? this.cnManifestFilter.userType === "customer"
            ? this.cnManifestFilter.clientPartner?.code
            : this.cnManifestFilter.clientPartner?.id || ""
          : "",
        this.cnManifestFilter.destinationCity?.code || "",
        this.cnManifestFilter.startDate
          ? this.cnManifestFilter.startDate.toLocaleDateString("fr-CA")
          : "",
        this.cnManifestFilter.endDate
          ? new Date(
              new Date(this.cnManifestFilter.endDate).setUTCHours(24)
            ).toLocaleDateString("fr-CA")
          : "",
        this.cnManifestFilter.sttNumbers
          .map((item: any) => {
            return item.name;
          })
          .toString(),
        this.cnManifestFilter.sttListingType === "booking_date"
          ? this.cnManifestFilter.userType === "console" ||
            this.cnManifestFilter.userType === "sub-console" ||
            this.cnManifestFilter.userType === "pos"
            ? "partner"
            : this.cnManifestFilter.userType
          : ""
      )
      .then((res: string) => {
        if (res) {
          const csvData = new Blob([res], { type: "text/csv;charset=utf-8;" });
          const exportFilename = "CN Manifests.csv";
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(csvData, exportFilename);
          } else {
            //In FF link must be added to DOM to be clicked
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(csvData);
            link.setAttribute("download", exportFilename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
        // IE11 & Edge
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, this.isAccountCountryOutsideID ? "Download Failed": "Unduh Gagal !", () =>
            this.exportManifestsToCsv()
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public initListClientPartnerOptions() {
    this.setClientPartnerOptions([]);
  }

  @Action
  public initCnManifestFilter() {
    this.setCnManifestFilterObject({
      sttListingType: "booking_date",
      userType: "console",
      clientPartner: "",
      destinationCity: "",
      startDate: "",
      endDate: "",
      sttNumbers: []
    });
    this.setCacheCnManifestFilterObject({
      sttListingType: "booking_date",
      userType: "console",
      clientPartner: "",
      destinationCity: "",
      startDate: "",
      endDate: "",
      sttNumbers: []
    });
  }

  @Action
  public initCnManifests() {
    this.setError(false);
    this.setErrorCause("");
    this.setFirstRequest(true);
    this.setLoadingCnManifests(false);
    this.setCnManifests(new CnManifestEntities(new Pagination(1, 10), []));
  }

  @Mutation
  private setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  private setCnManifests(data: CnManifestEntities) {
    this.cnManifests = data;
  }

  @Mutation
  private setLoadingCnManifests(value: boolean) {
    this.loadingCnManifests = value;
  }

  @Mutation
  private setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorDownload(value: boolean) {
    this.isErrorDownload = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  private setClientPartnerOptions(data: ClientPartnerOptions[]) {
    this.clientPartnerOptions = data;
  }

  @Mutation
  private setLoadingClientPartnerOptions(value: boolean) {
    this.loadingClientPartnerOptions = value;
  }

  @Mutation
  private setDestinationCityOptions(data: DestinationCityOptions[]) {
    this.destinationCityOptions = data;
  }

  @Mutation
  private setLoadingDestinationCityOptions(value: boolean) {
    this.loadingDestinationCityOptions = value;
  }

  @Mutation
  private setSttNumbersOptions(data: SttNumbersOptions[]) {
    this.sttNumbersOptions = data;
  }

  @Mutation
  public setCnManifestFilter(data: any) {
    this.cnManifestFilter[data.key] = data.value;
  }

  @Mutation
  private setCnManifestFilterObject(data: any) {
    this.cnManifestFilter = data;
  }

  @Mutation
  public setEndDate(val: Date) {
    this.cnManifestFilter.endDate = new Date(new Date(val).setUTCHours(24));
  }

  @Mutation
  private setCacheCnManifestFilterObject(data: any) {
    this.cacheCnManifestFilter = data;
  }

  get isAccountCountryOutsideID() {
    return AccountController.accountData.account_type_detail.countryCode.toLowerCase() !== 'id';
  }
}

export const CnManifestController = getModule(CnManifestStore);
