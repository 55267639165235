
import { Options, Vue, prop } from "vue-class-component";
import SelectPhoneCountry from "../../../shipment/booking/component/select-phone-country.vue";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { debounce } from "lodash";
import { numberOnly } from "@/app/infrastructures/misc/Utils";
import Geolocation from "../geolocation/index.vue";
import { ValidateInputPhoneNumber } from "@/app/infrastructures/misc/common-library/ValidateInputPhoneNumber";
import { PickupScheduleController } from "@/app/ui/controllers/PickupScheduleController";
import { PickupCorporateAddressRequest } from "@/data/payload/api/PickupScheduleApiRequest";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { PropType } from "vue";
import { codePhoneCountry } from "@/app/ui/views/shipment/booking/booking-utils";

class Props {
  formType = prop<string>({
    required: true,
  });
  clientId = prop<number>({
    default: 0,
    required: false,
  });
  idAddress = prop<number>({
    default: 0,
    required: false,
  });
  onClose = prop<any>({
    default: Function as PropType<() => void>,
    type: Function
  });
  modelValue = prop<any>({
    default: "",
    required: false
  });
  forceDefault = prop<boolean>({
    default: false,
    required: false
  });
  isVisible = prop<boolean>({
    default: false,
    required: false
  });
}

@Options({
  emits: ["onSuccess"],
  components: {
    SelectPhoneCountry,
    Geolocation
  },
  watch: {
    "modelValue": function() {
      this.getAllData();
    },
    isVisible(newVal) {
      if (newVal) {
        this.getIsDefault();
      } else {
        this.clearForm();
      }
    }
  },
})
export default class ModalAddNewAddress extends Vue.with(Props) {
  locationController = LocationController;
  addressName = ""
  picName = ""
  districtId: any = {}
  geoloc = ""
  postalCode = ""
  isDefault = false

  getAllData() {
    this.addressName = this.modelValue.pcaName
    this.picName = this.modelValue.pcaPicName
    this.address = this.modelValue.pcaAddress
    this.postalCode = this.modelValue.pcaPostalCode
    this.geoloc = this.modelValue.pcaLatlon
    this.phoneNumber = codePhoneCountry(this.modelValue.pcaPhoneNumber).number
    this.phoneNumberCode = codePhoneCountry(this.modelValue.pcaPhoneNumber).code
    this.districtId = this.modelValue.pcaDistrict
    this.isDefault = this.modelValue.pcaIsDefault
  }

  getIsDefault() {
    if (this.formType === "add" && this.forceDefault) {
      this.isDefault = this.forceDefault
    }
  }

  get accountData() {
    return AccountController.accountData;
  }

  fetchDistricts(search: string, cityCode = "", limit = 10) {
    return new Promise(resolve => {
      this.locationController
        .getDistrictList({
          page: 1,
          limit: limit,
          search,
          status: "active",
          cache: true,
          cityCode: cityCode,
          excludeCountry: "",
          type: ""
        })
        .then(res => resolve(res));
    });
  }

  get modalTitle() {
    if (this.formType === "edit") {
      return "Ubah alamat"
    } else {
      return "Tambah alamat baru"
    }
  }

  // phone number
  phoneNumber=""
  phoneNumberCode="+62"
  validPhoneNumber = true;

  phoneNumberError = {
    success: false,
    error: false,
    message: ""
  };

  error = {
    phoneNumber: false,
    phoneNumberCode: false,
  };

  setPhoneNumber(val: string) {
    let selectedPhoneCode = "";
    if (
      this.phoneNumberCode !== null &&
      this.phoneNumberCode !== ""
    ) {
      selectedPhoneCode = this.phoneNumberCode.substring(1);
    }
    const phoneNumberWithCode = `${selectedPhoneCode}${val}`;
    this.phoneNumberError = ValidateInputPhoneNumber(phoneNumberWithCode);
    this.setErrorPhoneNumberCode(this.phoneNumberError.error);
    this.phoneNumber = val
  }

  setPhoneCodeNumber(val: string) {
    this.phoneNumberCode = val
  }

  setErrorPhoneNumberCode(status: boolean) {
    this.error.phoneNumberCode = status;
  }

  // district
  get districtOptions() {
    return this.locationController.districtData.districtData.map(item => {
      return new OptionsClass({
        name: item.districtNameCityName,
        id: item.id
      });
    });
  }
  onFocusDistricts() {
    this.fetchDistricts("");
  }

  onSearchDistricts = debounce((search: string) => {
    this.fetchDistricts(search);
  }, 500);

  onSelectDistrict(item: OptionsClass): void {
    if (item.value) {
      // this.form.cityTimezone = item.code || "";
    } else {
      // this.form.cityTimezone = "";
    }
  }

  // address
  address = ""

  //postal code
  numberOnly(value: string) {
    return numberOnly(value);
  }

  get disableButtonSave() {
    return (
      !this.isFormValid ||
      this.phoneNumberError.error
    );
  }

  get isFormValid() {
    return (
      this.addressName &&
      this.picName &&
      this.address &&
      this.districtId.id &&
      this.geoloc
    );
  }

  clearForm() {
    this.addressName = ""
    this.picName = ""
    this.address = ""
    this.phoneNumber = ""
    this.phoneNumberCode = "+62"
    this.districtId = ""
    this.postalCode = ""
    this.geoloc = ""
    this.isDefault = false
    this.phoneNumberError.error = false
    this.phoneNumberError.success = false
    this.phoneNumberError.message = ""
    this.modelValue = ""
  }

  saveAddress() {
    const payload = new PickupCorporateAddressRequest({
      pcaAddress: this.address,
      pcaClientId: this.accountData.isInternalAccount ? this.clientId : 0,
      pcaDistrictId: Number(this.districtId.id),
      pcaIsDefault: this.isDefault,
      pcaLatlon: this.geoloc.replace(/\s+/g, ''),
      pcaName: this.addressName,
      pcaPhoneNumber: `${this.phoneNumberCode.substring(1)}${this.phoneNumber}`,
      pcaPicName: this.picName,
      pcaPostalCode: this.postalCode
    });

    if (this.formType === "add") {
      PickupScheduleController.addPickupCorporateAddress(payload)
    } else {
      PickupScheduleController.updatePickupCorporateAddress({data: payload, id: this.idAddress})
    }
  }

  get successTitle() {
    if (this.formType === "add") {
      return `"${this.addressName}" berhasil ditambah`
    } else {
      return `"${this.addressName}" berhasil diubah`
    }
  }

  get openSuccess() {
    return PickupScheduleController.isSuccessAddAddress;
  }

  onSuccessAddAddress() {
    PickupScheduleController.setSuccessAddAddress(false);
    this.$emit("onSuccess");
    this.clearForm();
  }
}
