
import { PickupScheduleController } from "@/app/ui/controllers/PickupScheduleController";
import { PickupCorporateAddress } from "@/domain/entities/PickupSchedule";
import { Vue, Options, prop } from "vue-class-component";
import ModalAddNewAddress from "../modal-add-new-address/index.vue";

class Props {
  isShown = prop<boolean>({
    default: false,
    type: Boolean
  });
  clientId = prop<number>({
    default: 0,
    required: false,
  });
  selectedAddress = prop<PickupCorporateAddress>({
    required: true
  });
}

@Options({
  emits: [
    "update:isShown",
    "selectAddress",
    "updateDefaultAddress",
    "addressDeleted"
  ],
  components: {
    ModalAddNewAddress
  }
})
export default class SavedAddressList extends Vue.with(Props) {
  isMainAddress = true;
  formType = ""
  isAddAddress = false
  editData: any = ""
  isDeleteConfirm = false;
  addressWillDeleted: PickupCorporateAddress | null = null;
  isForceDefault = false;

  get listDataSavedAddress(): PickupCorporateAddress[] {
    return PickupScheduleController.listPickupCorporateAddress;
  }

  onClose() {
    this.$emit("update:isShown", false);
  }

  onChooseButtonPressed(data: PickupCorporateAddress) {
    this.$emit("selectAddress", data);
    this.onClose();
  }

  async setPickupCorporateAddressDefault(data: PickupCorporateAddress) {
    await PickupScheduleController.setPickupCorporateAddressDefault(data);
    this.$emit("updateDefaultAddress");
    this.onClose();
  }

  onChooseDelete(data: PickupCorporateAddress) {
    this.isDeleteConfirm = true;
    this.addressWillDeleted = data;
  }

  async onSubmitDelete() {
    this.isDeleteConfirm = false;
    if (this.addressWillDeleted !== null) {
      await PickupScheduleController.deletePickupCorporateAddressDefault(
        this.addressWillDeleted
      );
      setTimeout(() => {
        this.$emit("addressDeleted");
      }, 300);
    }
  }

  showModalAddAddress(type: string, data?: PickupCorporateAddress) {
    if (type === "edit") {
      this.isForceDefault = data?.pcaIsDefault || false;
    } else {
      this.isForceDefault = false
    }
    this.formType = type
    this.editData = data || ""
    this.isAddAddress = true;
  }

  closeModalAddress() {
    this.isAddAddress = false;
    this.$emit("updateDefaultAddress");
  }
}
